import angular from 'angular';
import app from '../../app';

import { get } from 'lodash';

app.controller('labelMakerCtrl', [
  '$q',
  '$scope',
  '$translate',
  '$state',
  'SolrDocument',
  '$rootScope',
  'InventoryContainerService',
  '$injector',
  '$timeout',
  'LabelTemplatesService',
  'StorageCodes',
  'DisposalCodes',
  'Manufacturer',
  'CompanyService',
  'PpeService',
  'HazardService',
  'GHSService',
  function (
    $q,
    $scope,
    $translate,
    $state,
    SolrDocument,
    $rootScope,
    InventoryContainerService,
    $injector,
    $timeout,
    LabelTemplatesService,
    StorageCodes,
    DisposalCodes,
    Manufacturer,
    CompanyService,
    PpeService,
    HazardService,
    GHSService
  ) {
    const state = $state.params.state ? JSON.parse($state.params.state) : {};
    var vm = this;
    var companyId =
      ($rootScope.currentUser && $rootScope.currentUser.companyId) || $rootScope.companyId;
    var printType = (vm.printType = $state.params.type);
    var printItemId = $state.params.id;
    var printTypes = {
      sds: getDocumentById,
      container: getInventoryContainerById
    };
    const defaultLabelStyle = LabelTemplatesService.defaultLabelStyle;

    vm.templateTypes = [
      { type: 'MAIN', label: 'LABELMAKER.CONTENT.LABEL_TEMPLATE' },
      { type: 'BLANK', label: 'LABELMAKER.CONTENT.BLANK_WORKSPACE_LABELS' }
    ];
    vm.selectedTemplate = state.selectedTemplate || vm.templateTypes[0];
    vm.hazardTypes1 = ['healthHazard', 'flame', 'exclamationMark', 'corrosion', 'gasCylinder'];
    vm.hazardTypes2 = [
      'explodingBomb',
      'skullAndCrossbones',
      'flameOverCircle',
      'environment',
      'biohazard'
    ];
    vm.addNJCASToLabel = state.addNJCASToLabel;
    vm.showNJLabels = state.showNJLabels;
    vm.printQrCodeOnly = state.printQrCodeOnly;
    vm.printRequiredInfoOnly = state.printRequiredInfoOnly;
    for (let key in state) {
      if (state.hasOwnProperty(key)) {
        if (/showCustomFields/i.test(key)) vm[key] = state[key] || false;
      }
    }

    vm.fontSize = state.fontSize || 12;
    vm.hazardSize = state.hazardSize || 35;
    vm.borderWidth = state.borderWidth || 10;
    vm.borderColor = state.borderColor || '#000000';
    vm.riskPhrase =
      'Risk Phrase : copy only the “1st sentence” of CAUTION/WARNING/DANGER statements from the “Hazards Identification” section';
    vm.handlingPhrase =
      'Precautionary Measures, pulled from “Handling and Storage” copy only the “Handling” part of this section';
    vm.riskPhraseHTML = vm.riskPhrase;
    vm.handlingPhraseHTML = vm.handlingPhrase;
    vm.canPrint = false;
    vm.selectedLabelsForPrint = state.selectedLabelsForPrint || [];
    vm.additionalInformation = state.additionalInformation || '';
    $rootScope.sitename = state.sitename || $rootScope.sitename;
    $rootScope.companyId = state.companyId || $rootScope.companyId;
    if (state.siteLanguage) {
      $rootScope.siteLanguage = state.siteLanguage;
    }
    companyId = state.companyId || $rootScope.companyId;
    vm.showManu = state.showManu == null ? false : state.showManu;
    vm.showPPE = state.showPPE == null ? false : state.showPPE;
    vm.PPE = [];
    vm.PpeService = PpeService;
    vm.HazardService = HazardService;
    vm.ghsData = {};

    vm.codes = state.codes || {};
    vm.showStorageCode = state.showStorageCode;
    vm.showDisposalCode = state.showDisposalCode;

    vm.labelStyle = angular.extend({}, defaultLabelStyle, state.labelStyle);

    vm.labelTemplates = LabelTemplatesService.known.map(
      LabelTemplatesService.calculateMeasuranceInPixel
    );
    vm.labelTemplate = state.labelTemplate || vm.labelTemplates[0];

    //init qr code settings
    vm.qrcode = state.qrcode == null ? false : state.qrcode;
    vm.v = 4;
    vm.e = 'M';
    vm.qrcodeSize = state.qrcodeSize || 100;

    vm.autoAdjustable = state.autoAdjustable == null ? true : state.autoAdjustable;

    var emphases = [
      { match: /:/, replace: /.*?:/ },
      { match: /#/, replace: /.*?#/ },
      { match: /!/, replace: /.*?!/ },
      { match: /\b[A-Z]{2,}\b/g, replace: /\b[A-Z]{2,}\b/g }
    ];

    vm.getHeight = function (template) {
      if (template.pageSize === '3in 6in') return 12;
      if (template.pageSize === '4in 6in' && template.printType === 'continuous') return 20;
      if (template.pageSize === '2.4in 6in' && template.printType === 'continuous') return 10;
      if (template.numRows <= 1) {
        switch (template.pageSize) {
          case '4in 6in':
            return 25;
          default:
            return 28.8;
        }
      }
      if (template.numRows == 2) return 17.3;
      if (template.numRows == 5) return 9;

      return 15;
    };

    vm.generateViewSdsUrl = $rootScope.generateUrl.bind(null, 'viewSds');

    $scope.range = function (n) {
      var arr = [];
      for (var i = 0; i < n; i++) arr.push(i);
      return arr;
    };

    $scope.upDownControl = function (target, amount) {
      vm[target] = Math.max(parseInt(vm[target]) + amount, 0);

      if (vm.qrcodeSize <= 50) {
        vm.qrcodeSize = 50;
      }
      LabelTemplatesService.upDownControl(target, amount);
    };

    //trims one sentence or line from the target string on the viewmodel
    $scope.trimSection = function (target) {
      var lines = vm[target].split(/\n/);
      var lastLine = lines[lines.length - 1];
      lastLine = lastLine.trim();

      if (lastLine == '') {
        //empty line
        lines.pop();
      } else if (lastLine[lastLine.length - 1] != '.' && lastLine.indexOf('.') >= 0) {
        //sentence fragment
        lastLine = lastLine.slice(0, lastLine.lastIndexOf('.') + 1);
        lines[lines.length - 1] = lastLine;
      } else {
        var sentences = lastLine.match(/.*?\./g);

        if (sentences) {
          sentences.pop();
          lines[lines.length - 1] = sentences.join('');
        } else {
          lines.pop();
        }
      }

      vm[target] = lines.join('\n');
      $scope.updateSectionHTML(target);
    };

    $scope.getSectionFullText = function (target) {
      if (target == 'riskPhrase') vm[target] = vm.printItem.hazards_identification;
      else if (target == 'handlingPhrase') vm[target] = vm.printItem.handling_storage;

      vm[target] = decodeURIComponent(vm[target]);
      $scope.updateSectionHTML(target);
    };

    $scope.joinSectionLines = function (target) {
      if (!vm[target]) return;

      vm[target] = vm[target].replace(/\n/g, ' ');
      vm[target] = vm[target].replace(/\s+/g, ' ');
      $scope.updateSectionHTML(target);
    };

    $scope.updateSectionHTML = function (target) {
      var targetHTML = target + 'HTML';
      vm[targetHTML] = LabelTemplatesService.addEmphasis(vm[target], emphases);
    };

    function getDocumentById(documentId) {
      var options = {};
      options.idList = 'id:' + documentId;
      options.fieldList =
        'id,chemicalFriendlyName,handling_storage,safe_handling,hazards_identification,hazardType,storageCodes,disposalCodes,manufacturer,language,attr_*,new_jersey_right,ppe_*';

      SolrDocument.findByIdList(options)
        .$promise.then(function (results) {
          const doc = results.response.docs[0];
          vm.PPE = doc[`ppe_${$rootScope.companyId}`] ? doc[`ppe_${$rootScope.companyId}`] : [];
          $translate(
            'LABELMAKER.CONTENT.SAFETY_DATA_SHEET',
            undefined,
            undefined,
            undefined,
            doc.language[0]
          ).then(function (translation) {
            vm.sdsLocationHTML = '<strong>' + translation + '</strong>';
          });

          return $q
            .all({
              storage:
                doc.storageCodes &&
                StorageCodes.findOne({
                  filter: {
                    where: {
                      id: { inq: doc.storageCodes },
                      isEnabled: true,
                      companySettingId: companyId
                    }
                  },
                  fields: ['id', 'title', 'color', 'description'],
                  limit: 1
                }).$promise.catch(angular.noop),
              disposal:
                doc.disposalCodes &&
                DisposalCodes.findOne({
                  filter: {
                    where: {
                      id: { inq: doc.disposalCodes },
                      isEnabled: true,
                      companySettingId: companyId
                    }
                  },
                  fields: ['id', 'title', 'color', 'description'],
                  limit: 1
                }).$promise.catch(angular.noop),
              manu:
                doc.manufacturer &&
                Manufacturer.findOne({
                  filter: { where: { id: doc.manufacturer } },
                  fields: ['id', 'name']
                })
                  .$promise.then(function (resp) {
                    return resp.name;
                  })
                  .catch(angular.noop),
              companyPrintableAttrs: CompanyService.getCurrentCompanyPromise().$promise.then(
                function () {
                  companyId = $rootScope.companyId;

                  return $rootScope.companySettings.printableAttrs || [];
                }
              )
            })
            .then(function (resp) {
              const docAttr = doc['attr_' + companyId] || [];

              const [printableAttrs, attr] = docAttr.reduce(
                function (sum, el) {
                  const arr = el.split('=');
                  const ret = { color: '' };
                  if (arr.length === 2) {
                    ret.header = arr[0];
                    ret.title = arr[1];
                  } else ret.header = el;

                  if (resp.companyPrintableAttrs.includes(ret.header)) {
                    sum[0].push(ret);
                  } else {
                    sum[1].push(ret);
                  }

                  return sum;
                },
                [[], []]
              );
              vm.codes = state.codes || {
                storage: resp.storage,
                disposal: resp.disposal,
                customs: attr,
                customPrintableFields: printableAttrs
              };
              vm.manu = resp.manu;

              vm.printItem = doc;
              vm.printItem.name = decodeURIComponent(vm.printItem.chemicalFriendlyName);
              vm.printItem.new_jersey_right = decodeURIComponent(
                vm.printItem.new_jersey_right || ''
              );

              if (vm.printItem.safe_handling && vm.printItem.safe_handling !== 'undefined') {
                vm.safeHandlingPhrase = decodeURIComponent(vm.printItem.safe_handling);
              } else {
                vm.safeHandlingPhrase = decodeURIComponent(vm.printItem.handling_storage);
              }

              vm.safeHandlingPhraseHTML = LabelTemplatesService.addEmphasis(
                vm.safeHandlingPhrase,
                emphases
              );

              trackPage(vm.printItem.name);
            });
        })
        .then(function () {
          $timeout(vm.adjustSizes, 500);
        });
    }

    vm.getAttrLangName = CompanyService.getAttrLangName;

    vm.togglePrintSelect = function (row, column) {
      if (vm.labelTemplate.printType === 'continuous') return;

      vm.selectedLabelsForPrint[row][column] = !vm.selectedLabelsForPrint[row][column];
      vm.canPrint = vm.selectedLabelsForPrint.some(function (element) {
        return element.some(function (item) {
          return item === true;
        });
      });
    };

    vm.selectedSecond = function (state) {
      if (vm.selectedLabelsForPrint[0][1] !== undefined) {
        vm.selectedLabelsForPrint[0][1] = state;
      } else if (vm.selectedLabelsForPrint[1] && vm.selectedLabelsForPrint[1][0] !== undefined) {
        vm.selectedLabelsForPrint[1][0] = state;
      }
    };

    vm.changeAddNJCasToLabels = function () {
      if (!vm.addNJCASToLabel && vm.showNJLabels) {
        vm.showNJLabels = false;
      }
      vm.changePrintNJLabels();
    };

    vm.changePrintNJLabels = function () {
      LabelTemplatesService.flushPreviousLabels();
      vm.adjustSizesDelayed();
      vm.selectedSecond(!!vm.showNJLabels);
    };

    vm.changePrintQrCodeOnly = function () {
      if (vm.printQrCodeOnly) {
        vm.showManu = true;
      }
      if (vm.printRequiredInfoOnly) {
        vm.printRequiredInfoOnly = false;
      }
      vm.adjustSizesDelayed(500);
    };

    vm.changePrintRequiredInfoOnly = function () {
      if (vm.printQrCodeOnly) {
        vm.printQrCodeOnly = false;
      }
      if (vm.printRequiredInfoOnly && vm.showNFPA) {
        vm.showNFPA = false;
      }
      vm.adjustSizesDelayed(500);
    };

    vm.prepareSelectedLabelsForPrint = async function (delay, selected = false) {
      if (!vm.labelTemplate.numRows || vm.labelTemplate.numRows < 1) {
        vm.labelTemplate.numRows = 1;
      }
      await CompanyService.getCurrentCompanyPromise().$promise;
      vm.borderWidth = 10;
      vm.canPrint = false;
      vm.showNJLabels = false;
      vm.selectedLabelsForPrint = [];
      LabelTemplatesService.flushPreviousLabels();
      for (var i = 0; i < vm.labelTemplate.numRows; i++) {
        for (var k = 0; k < vm.labelTemplate.numLabelsPerRow; k++) {
          if (!vm.selectedLabelsForPrint[i]) {
            vm.selectedLabelsForPrint[i] = [];
          }
          if (vm.labelTemplate.printType === 'continuous') {
            vm.selectedLabelsForPrint[i][k] = true;
          } else {
            vm.selectedLabelsForPrint[i][k] = selected;
          }
        }
      }

      if (vm.selectedLabelsForPrint[0]) {
        vm.selectedLabelsForPrint[0][0] = true;
        vm.canPrint = true;
      }
      vm.addNJCASToLabel = $rootScope.companySettings.addNJCASToLabel;
      vm.qrcode = $rootScope.companySettings.qrCodeOnLabelByDefault;
      if (vm.labelTemplate.qrcodeDisabled) {
        vm.qrcode = false;
        vm.printQrCodeOnly = false;
      }

      // in the safary without delay did not work correctly
      vm.adjustSizesDelayed();
    };

    vm.onTemplateChange = function () {
      const labelTemplates = vm.labelTemplatesFilter(vm.labelTemplates, vm.selectedTemplate);
      if (!labelTemplates.find(template => template.name === vm.labelTemplate.name)) {
        const currentLabelTemplate = vm.labelTemplates.find(
          template => template.name === vm.labelTemplate.name
        );
        if (
          vm.previousLabelTemplate &&
          labelTemplates.find(template => template.name === vm.previousLabelTemplate.name)
        ) {
          vm.labelTemplate = vm.previousLabelTemplate;
        } else {
          vm.labelTemplate = labelTemplates[0];
        }
        vm.previousLabelTemplate = currentLabelTemplate;
      }
      if (vm.selectedTemplate.type == 'MAIN') {
        vm.adjustSizesDelayed(500);
      } else {
        vm.fontSize = state.fontSize || 12;
      }
    };

    vm.isNeedSelect = function (column, row) {
      if (vm.labelTemplate.numRows * vm.labelTemplate.numLabelsPerRow == 1) {
        vm.canPrint = true;
        return false;
      }
      if (!(vm.selectedLabelsForPrint[column] || [])[row]) {
        return true;
      } else {
        return false;
      }
    };

    vm.shiftSeparatedLabelState = function () {
      let labelState = null;
      if (state.separatedLabelsState) {
        labelState = state.separatedLabelsState.shift();
      }
      return labelState;
    };

    const getUrl = function () {
        // NOTE: presence of query string messes things up
        return window.location.href;
        //return window.location.origin + window.location.pathname;
      },
      getState = function () {
        const state = {
          autoAdjustable: false,
          sitename: $rootScope.sitename,
          companyId: $rootScope.companyId,
          selectedTemplate: vm.selectedTemplate,
          fontSize: vm.fontSize,
          hazardSize: vm.hazardSize,
          borderWidth: vm.borderWidth,
          borderColor: vm.borderColor,
          selectedLabelsForPrint: vm.selectedLabelsForPrint,
          labelTemplate: vm.labelTemplate,
          qrcode: vm.qrcode,
          qrcodeSize: vm.qrcodeSize,
          borderStyle: vm.borderStyle,
          showManu: vm.showManu,
          showPPE: vm.showPPE,
          codes: vm.codes,
          showStorageCode: vm.showStorageCode,
          showDisposalCode: vm.showDisposalCode,
          labelStyle: vm.labelStyle,
          additionalInformation: vm.additionalInformation,
          addNJCASToLabel: vm.addNJCASToLabel,
          showNJLabels: vm.showNJLabels,
          separatedLabelsState: LabelTemplatesService.getLabelsStateData(),
          printQrCodeOnly: vm.printQrCodeOnly,
          printRequiredInfoOnly: vm.printRequiredInfoOnly,
          siteLanguage: $rootScope.siteLanguage
        };

        for (let index = 0; index < vm.codes.customs.length; index++) {
          state['showCustomFields' + index] = vm['showCustomFields' + index] || false;
        }
        return JSON.stringify(state);
      },
      getCurrentUser = function () {
        return $rootScope.currentUser ? JSON.stringify($rootScope.currentUser) : null;
      };

    $scope.printDocument = function ($event, form) {
      $event.preventDefault();

      if (vm.canPrint) {
        form.commit({
          url: getUrl(),
          state: getState(),
          currentUser: getCurrentUser(),
          printOpts: JSON.stringify({ pageRanges: '' })
        });
      } else {
        showModal();
      }
    };

    const getBottomPadding = function (numRows) {
        return 30 / numRows;
      },
      reset = function () {
        vm.fontSize = state.fontSize || 12;
        vm.hazardSize = state.hazardSize || 35;
        vm.qrcodeSize = state.qrcodeSize || 100;
        $scope.$apply();
      },
      decrease = function (card, bottomPadding) {
        if (LabelTemplatesService.isContentBigger(card)) {
          while (
            LabelTemplatesService.isContentBigger(card) &&
            vm.fontSize > LabelTemplatesService.labelPrintSettings.minFontSize
          ) {
            vm.fontSize -= LabelTemplatesService.labelPrintSettings.fontStep;
            $scope.$apply();
            if (!LabelTemplatesService.isContentBigger(card)) break;
            vm.hazardSize -= LabelTemplatesService.labelPrintSettings.hazardStep;
            $scope.$apply();
            if (!LabelTemplatesService.isContentBigger(card)) break;
            vm.qrcodeSize -= LabelTemplatesService.labelPrintSettings.codeStep;
            $scope.$apply();
          }

          // in cases when the size hase a maximum decrease, I try to decrease the border
          if (LabelTemplatesService.isContentBigger(card)) {
            const borderStep = 1;
            const minBorderWidth = 3;
            while (LabelTemplatesService.isContentBigger(card) && vm.borderWidth > minBorderWidth) {
              vm.borderWidth -= borderStep;
              $scope.$apply();
            }
          }

          // in cases when the size hase a maximum decrease with border, I try to decrease the hazard
          if (LabelTemplatesService.isContentBigger(card)) {
            const minHazardSize = 11;
            const step = 1;
            while (LabelTemplatesService.isContentBigger(card) && vm.hazardSize > minHazardSize) {
              vm.hazardSize -= step;
              $scope.$apply();
            }
          }
        }
      },
      increase = function (card, bottomPadding) {
        if (LabelTemplatesService.isContentSmaller(card)) {
          while (
            LabelTemplatesService.isContentSmaller(card) &&
            vm.fontSize <= LabelTemplatesService.labelPrintSettings.maxFontSize
          ) {
            vm.fontSize += LabelTemplatesService.labelPrintSettings.fontStep;
            $scope.$apply();
            if (!LabelTemplatesService.isContentSmaller(card)) break;
            vm.hazardSize += LabelTemplatesService.labelPrintSettings.hazardStep;
            $scope.$apply();
            if (!LabelTemplatesService.isContentSmaller(card)) break;
            vm.qrcodeSize += LabelTemplatesService.labelPrintSettings.codeStep;
            $scope.$apply();
          }
        }
      };
    vm.adjustSizes = function () {
      if (!vm.autoAdjustable) {
        return;
      }
      const card = angular.element('.styledLabel')[0],
        bottomPadding = getBottomPadding(vm.labelTemplate.numRows);
      if (!card) {
        return;
      }
      reset();
      decrease(card, bottomPadding);
      increase(card, bottomPadding);
      // decrease again in case increase got overboard
      decrease(card, bottomPadding);
    };
    vm.adjustSizesDelayed = function (delay) {
      if (!vm.autoAdjustable) {
        return;
      }
      $timeout(() => {
        vm.adjustSizes();
        LabelTemplatesService.adjustLabelsSize(vm);
      }, delay || 100);
    };

    vm.selectedLabelsForPrint.length || vm.prepareSelectedLabelsForPrint(1000);
    if (state.additionalInformation) {
      $scope.updateSectionHTML('additionalInformation');
    }

    vm.handleMouseWheel = function (event) {
      event.preventDefault();
    };

    vm.labelTemplatesFilter = function (items, selectedTemplate) {
      if (selectedTemplate.type === 'GHS_SUPPLIER') {
        return items.filter(item => item.category === 'ghs_supplier');
      } else {
        return items.filter(item => item.category !== 'ghs_supplier');
      }
    };

    vm.parseData = function (data) {
      return data.replace(/\.;/g, '.<br>').replace(/\|/g, ' ');
    };

    LabelTemplatesService.getLabelSettings(companyId).then(function (settings) {
      vm.borderStyles = settings.borderStyles;
      vm.borderStyle = state.borderStyle || vm.borderStyles[0];

      vm.labelTemplates = LabelTemplatesService.pick(settings.labelTemplates).map(
        LabelTemplatesService.calculateMeasuranceInPixel
      );
      vm.labelTemplate = state.labelTemplate || vm.labelTemplates[0];

      if (vm.labelTemplates.find(template => template.category === 'ghs_supplier')) {
        GHSService.getData([printItemId]).then(data => {
          vm.ghsData = data;
          if (vm.ghsData[printItemId]) {
            const ghsSdsData = vm.ghsData[printItemId];
            const ghsDataFields = [
              'authoringProductNumber',
              'signalWord',
              'hazardStatements',
              'precPrevention',
              'precResponse',
              'precStorage',
              'precDisposal',
              'hnoc',
              'productNumber'
            ];
            let isPresentGhsData = false;
            for (const ghsDataField of ghsDataFields) {
              if (ghsSdsData[ghsDataField]) {
                isPresentGhsData = true;
                break;
              }
            }
            if (isPresentGhsData) {
              vm.templateTypes.push({ type: 'GHS_SUPPLIER', label: 'GHS.LABEL.TITLE' });
            }
          }
        });
      }

      vm.labelStyle = angular.extend({}, defaultLabelStyle, settings.labelStyle, state.labelStyle);

      printTypes[printType](printItemId);
    });

    //protect against adblockers
    try {
      //throws if not available
      var Analytics = $injector.get('Analytics');
    } catch (e) {
      console.error('GA is not available');
    }

    function trackPage(name) {
      if (!$rootScope.checkIfUserIs('admin')) {
        gtag('event', 'page_view', {
          page_title: 'Make label for "' + name + '"',
        });
      }
    }

    function showModal() {
      angular.element('#printModal').modal('show');
    }

    function getInventoryContainerById(id) {
      InventoryContainerService.getContainerById(id).then(function (container) {
        vm.printItem = container;

        trackPage(vm.printItem.name);
      });
    }
  }
]);
